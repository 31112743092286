import { Component, Input } from '@angular/core';
import { SettlementsService } from 'src/app/services/settlements.service';
import { ngxCsv } from 'ngx-csv';
import * as bs from 'bootstrap';
import * as xml2js from 'xml2js';
import { IconsService } from 'src/app/shared/services/icons.service';

@Component({
  selector: 'app-report-invoices-generic-canceled',
  template: `
    <button type="button" class="btn btn-info rounded-5 shadow w-100" (click)="modalReport()">
      <fa-icon [icon]="icons.faFileExcel" class="me-2"></fa-icon>
      Genéricas Canceladas
    </button>
    <app-modal-invoices-generic-canceled [profile]="profile" (createReport)="generateCancelReport($event)"></app-modal-invoices-generic-canceled>
  `,
  styleUrls: []
})
export class ReportInvoicesGenericCanceledComponent {
  @Input() profile: any;
  
    loading: boolean = false;
  
    payload: any = {
      page: 1,
      per_page: 10000,
      tipo: 5,
      canceled: true,
      created_at_since: '',
      created_at_to: '',
      sort: 'created_at',
      order: 'DESC'
    };
  
    constructor(
      private store: SettlementsService,
      public icons: IconsService
    ) { }
  
    modalReport() {
      console.log('CANCELD MODAL')
      new bs.Modal(<HTMLInputElement>document.getElementById('modalInvoicesCanceled')).show();
    }
  
    generateCancelReport(data: any) {
      this.loading = true;
      console.log("REPORTE canceladas ===> ", data);
      this.payload.created_at_since = data.created_at_since;
      this.payload.created_at_to = data.created_at_to;
      this.payload.profile_id = this.profile.id;
      console.log('canceled payload ===> ', this.payload);
      this.store.getInvoicesQuery(this.payload).subscribe(async (res: any) => {
        let report: any[] = [];
        await res.data.map(async (invoice: any) => {
          const parser = new xml2js.Parser({
            trim: true,
            explicitArray: true,
          });
          await parser.parseString(invoice.cfdixml, (err: any, result: any) => {
            // console.log('result => ', result);
            // console.log('receptor ==> ', result['cfdi:Comprobante']['cfdi:Receptor'][0]['$']);
            console.log('WAIT ====> ', result['cfdi:Comprobante']);
            const main = result['cfdi:Comprobante']['$'];
            const receptor = result['cfdi:Comprobante']['cfdi:Receptor'][0]['$'];
            const complemento = result['cfdi:Comprobante']['cfdi:Complemento'][0]['tfd:TimbreFiscalDigital'][0]['$'];
  
            report.push({
              serie: main['Serie'],
              folio: main['Folio'],
              fecha: main['Fecha'],
              uuid: complemento['UUID'],
              rfc: receptor['Rfc'],
              razon: receptor['Nombre'],            
              subTotal: main['SubTotal'],
              total: main['Total'],
            });
          });
        });
  
        console.log('REPORT BUILT ===> ', report);
        let options = {
          title: 'Reporte de facturas canceladas',
          headers: ['Serie', 'Folio', 'Fecha', 'Folio fiscal', 'RFC receptor', 'Razón social', 'Subtotal', 'Total'],
          fieldSeparator: ',',
          quoteStrings: '"',
          decimalseparator: '.',
          showLabels: true,
          showTitle: true,
          useBom: true,
        };
  
        new ngxCsv(report, `reporte_factuas_canceladas`, options);
        this.loading = false;
      });
    }
}
