import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TasksService } from 'src/app/services/tasks.service';

import { BreadcrumbService } from 'xng-breadcrumb';
import { SettlementsService } from 'src/app/services/settlements.service';
import { DateService } from 'src/app/shared/services/date.service';
import { SummaryService } from 'src/app/services/summary.service';
import { IconsService } from 'src/app/shared/services/icons.service';

import { ModalSettlementStatementComponent } from 'src/app/components/modals/modal-settlement-statement.component';
import { ModalSettlementBillReportComponent } from 'src/app/components/modals/modal-settlement-bill-report.component';

@Component({
  selector: 'app-dashboard-settlement',
  template: `
    <div class="row">
      <div class="col-sm-12 offset-sm-0 col-lg-9 offset-lg-3 mt-5">
        <div class="row mb-4">
          <div class="col-md-6">
              <xng-breadcrumb ></xng-breadcrumb>
            <div class="row">
              <div class="col-md-8">
              <h1 class="mt-3">Tablero</h1>
                <h5 class="mb-0">Condominio</h5>
                <h1>{{settlement.name}}</h1>
              </div>
              <div class="col-md-12">
                <p class="mb-2"><small>{{settlement.settlement?.address}}, {{settlement.settlement?.city}}, {{settlement.settlement?.country}}</small></p>
                <p class="mb-0" style="font-size: 17px;"><small>{{settlement.settlement?.email}}</small></p>
                <p class="mb-" style="font-size: 17px;"><small>{{settlement.settlement?.phone}}</small></p>
                <hr>
              </div>
              <div class="col-md-12">
                <div class="card rounded-5 shadow mb-5">
                  <div class="card-body py-4 px-3">
                    <!-- div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <select class="form-select form-select-lg" (change)="updatePeriodo($event)">
                            <option selected>Selecciona un periodo</option>
                            <option *ngFor="let periodo of periodos" [value]="periodo.id">{{periodo.name}}</option>
                          </select>
                        </div>
                      </div>
                    </!-->
                    <div class="d-flex">
                      <div class="flex-fill">
                        <button type="button" class="btn btn-outline-primary rounded-4" (click)="generateStatement()">Generar Estado de cuenta</button>
                      </div>
                      <div class="flex-fill">
                        <button type="button" class="btn btn-outline-primary rounded-4 ms-2" (click)="generateReport()">Generar reporte</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 text-end mt-2">
            <div class="card rounded-5 shadow">
              <div class="card-body p-4" >
                <div class="row">
                  <div class="col-md-6 text-center">
                    <h1 [countUp]="avg"></h1>
                    <p>Calificación</p>
                  </div>
                  <div class="col-md-6 text-center">
                    <h1 [countUp]="stats.length"></h1>
                    <p>Tareas acumuladas</p>
                  </div>
                  <div class="col-md-6 text-center">
                    <h1 [countUp]="settlement.tanques?.length"></h1>
                    <p>Tanques</p>
                  </div>
                  <div class="col-md-6 text-center">
                    <h1 [countUp]="settlement.units?.length"></h1>
                    <p>Unidades</p>
                  </div>
                  
                  <div class="col-12 col-sm-4 text-center" #portafolioChart id="portafolioChart" *ngIf="portafolio.past_due">
                    <app-pie-chart-portfolio [data]="portafolio"></app-pie-chart-portfolio>
                  </div>
                  <div class="col-12 col-sm-8">
                    <div class="row mt-4">
                      <div class="col-6 text-center" *ngIf="portafolio.past_due">
                        <h4 [countUp]="portafolio.past_due" [currency]="'$'" class="mb-0"></h4>
                        <p class="mb-0 text-muted">Vencido</p>
                      </div>
                      <div class="col-6 text-center" *ngIf="portafolio.current">
                        <h4 [countUp]="portafolio.current" [currency]="'$'" class="mb-0"></h4>
                        <p class="mb-0 text-muted">Vigente</p>
                      </div>
                      <div class="col-6 text-center mt-4" *ngIf="portafolio.liters">
                        <h4 [countUp]="portafolio.liters" class="mb-0"></h4>
                        <p class="mb-0 text-muted">Total lts. cartera</p>
                      </div>
                      <div class="col-6 text-center mt-4">
                        <h4 [countUp]="portafolio.paid" [currency]="'$'" class="mb-0"></h4>
                        <p class="mb-0 text-muted">Total pagos</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 mt-2">
                    <h5>Clientes con más adeudos (Top 3)</h5>
                    <table class="table table-striped table-sm">
                      <thead>
                        <tr>
                          <th></th>
                          <th class="text-end">Total</th>
                          <th class="text-end">Lts.</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let ov of portafolio.overview">
                          <td>#{{ov.id}} {{ov.reference}}</td>
                          <td class="text-end">{{ov.total | currency}}</td>
                          <td class="text-end">{{ov.consumo_lt | number: '1.2-2'}} Lts.</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="col-12 text-center">
                    <p class="text-muted text-sm">La cartera se consideran todos los recibos emitidos generados.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-12">
            <div class="card rounded-5 shadow mb-5" id="cardConsumptions" #cardConsumptions>
              <div class="card-body py-4 px-5">
                <div class="row">
                  <div class="col-12">
                    <h5>Consumo del periodo</h5>
                  </div>
                  <div class="col-12" *ngIf="consumption_consumptions.length>0">
                    <app-line-chart-consumption
                      [data]="consumption_consumptions"
                      [start_date]="payload.start_date"
                      [end_date]="payload.end_date"
                    ></app-line-chart-consumption>
                  </div>
                  <div class="col-12" *ngIf="consumption_consumptions.length==0">
                    <div class="text-center py-5" >
                      <img src="/assets/images/empty-state.png" width="240px">
                      <p class="text-muted">No se encontraron registros</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="card rounded-5 shadow mb-5" id="cardConsumptions" #cardConsumptions >
              <div class="card-body py-4 px-5" >
                <div class="row">
                  <div class="col-12">
                    <h5>Lts. Trabajados VS Lts. Consumidos</h5>
                  </div>
                  <div class="col-12" *ngIf="consumption_tanques.length>0">
                    <app-stl-bar-chart-consumptions
                      [data]="consumption_data"
                    ></app-stl-bar-chart-consumptions>
                  </div>
                  <div class="col-12" *ngIf="consumption_tanques.length==0">
                    <div class="text-center py-5" >
                      <img src="/assets/images/empty-state.png" width="240px">
                      <p class="text-muted">No se encontraron registros</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- div class="col-md-7">
            <div class="card rounded-5 shadow mb-5">
              <div class="card-body py-4 px-5">
                <div class="row">
                  <div class="col-md-12">
                    
                  </div>
                  <div class="col-md-12 mt-4">
                    <table class="table table-sm">
                      <tr>
                        <th>Calificación prom.</th>
                        <th>Tarea</th>
                        <th>Técnico</th>
                        <th>Fecha</th>
                      </tr>
                      <tr *ngFor="let stat of stats">
                        <td>{{stat.avg_grade}}</td>
                        <td>{{stat.task_id}}</td>
                        <td>{{stat.user_id}}</td>
                        <td>{{stat.created_at | date: 'dd/MM/yy'}}</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </!-->
          <!-- div class="col-md-5">
            <div class="card rounded-5 shadow">
              <div class="card-body py-4 px-5">
                <div class="row" *ngIf="stats.length>0">
                  <app-bar-chart [multi]="stats"></app-bar-chart>
                </div>
              </div>
            </div>
          </!-->
        </div>
      </div>
    </div>
    <app-modal-settlement-bill-report></app-modal-settlement-bill-report>
    <app-modal-settlement-statement [settlement]="settlement"></app-modal-settlement-statement>
  `,
  styleUrls: []
})
export class DashboardSettlementComponent implements OnInit {
  @ViewChild(ModalSettlementStatementComponent, { static: true }) modalSettlementStatement !: ModalSettlementStatementComponent;
  @ViewChild(ModalSettlementBillReportComponent, { static: true }) modalSettlementBillReport !: ModalSettlementBillReportComponent;

  settlementId: number = 0;
  stats: any[] = [];
  avg: number = 0;
  settlement: any = {};
  portafolio: any = {};

  profile: any = null;
  permissions: any = null;

  filter: any[] = [
    { id: 1, name: 'Semanal' },
    { id: 2, name: 'Mensual' },
    { id: 3, name: 'Anual' },
  ];

  periodos: any[] = [
    {id: 1, name: 'Enero'},
    {id: 2, name: 'Febrero'},
    {id: 3, name: 'Marzo'},
    {id: 4, name: 'Abril'},
    {id: 5, name: 'Mayo'},
    {id: 6, name: 'Junio'},
    {id: 7, name: 'Julio'},
    {id: 8, name: 'Agosto'},
    {id: 9, name: 'Septiembre'},
    {id: 10, name: 'Octubre'},
    {id: 11, name: 'Noviembre'},
    {id: 12, name: 'Diciembre'}
  ];

  year: any[] = [
    {id: 2024, name: 2024},
    // {id: 2025, name: 2025},
    // {id: 2026, name: 2026},
    // {id: 2027, name: 2027},
    // {id: 2028, name: 2028},
    // {id: 2029, name: 2029},
    // {id: 2030, name: 2030},
  ];

  payload: any = {
    year: 2024,
    month: [1,2,3,4,5],
    state: [1,2],
  };

  consumption_data: any[] = [];
  consumption_consumptions: any[] = [];
  consumption_tanques: any[] = [];

  constructor(
    private store: SettlementsService,
    private tasksService: TasksService,
    private activateRoute: ActivatedRoute,
    private summ: SummaryService,
    private route: Router,
    private breadcrumbService: BreadcrumbService,
    private dateService: DateService,
    public icons: IconsService
  ) {
    this.activateRoute.params.subscribe(params => {
      this.settlementId = params['id'];

      this.profile = localStorage.getItem('profile') != null ? JSON.parse(localStorage.getItem('profile')!).profile : null;
      this.permissions = localStorage.getItem('permissions') != null ? JSON.parse(localStorage.getItem('permissions')!) : null;
      this.payload.profile_id = this.profile.id;


      this.payload.settlement_id = this.settlementId;



      this.loadSettlement();
    });
  }

  ngOnInit(): void {
    // this.loadStatSummary();
    this.updatePeriodo(this.dateService.getPeriodo(new Date()));
    this.loadPortafolio();
    this.loadTankSummary();
  }

  loadSettlement() {
    this.store.getSettlementNested(this.settlementId).subscribe((response: any) => {
      this.settlement = response;
      this.breadcrumbService.set('@settlementName', this.settlement.name); 
      this.breadcrumbService.set('@dashboard', `Tablero de condominio`);      
    });
  }

  loadStatSummary() {
    console.log('payload ===> ', this.payload);
    this.tasksService.getTechStatSummariesFilter(this.payload).subscribe((response: any) => {
      console.log(response);
      this.stats = response.data;
      
      let local_avg = 0;
      response.data.map((item: any) => {
        local_avg += item.avg_grade;
      });
      if (response.data.length > 0) {
        this.avg = local_avg / response.data.length;
      } else {
        this.avg = 0;
      }
    })
  }

  updatePeriodo(event: any) {
    const year = 2024;
    console.log("==> ", event.target);
    let month;
    if (event.target) {
      month = event.target.value;
    } else {
      month = event;
    }

    

    const startDate = new Date(year, month - 1, 1);
  
    // To get the end date, move to the first day of the next month, then subtract one day
    const endDate = new Date(year, month, 0);

      this.payload.created_at_since = startDate.toISOString().substring(0, 10), // Format as "YYYY-MM-DD"
      this.payload.created_at_to = endDate.toISOString().substring(0, 10) // Format as "YYYY-MM-DD"
    
    this.loadStatSummary();
  }

  

  loadTankSummary() {
    console.log('loadTankSummary settlementId => ', this.settlementId)
    this.summ.getConsumeSummaryBySettlementMonth(this.payload).subscribe((data: any) => {
      console.log('getTanqueSummary data => ', data);
      this.consumption_data = data;
      this.consumption_consumptions = data.consumptions;
      this.consumption_tanques = data.readings;
    });
  }

  loadPortafolio() {
    this.store.getBillsPortafolioSettlement({ profile_id: this.profile.id, settlement_id: this.settlementId }).subscribe((response: any) => {
      console.log('getBillsPortfolio response => ', response);
      this.portafolio = response;
    });
  }

  generateReport() {
    console.log('generateReport');
    console.log('payload => ', this.payload);
    // TODO: Get all bills for the period
    // And link the tank to the bill
    const modalTemplate: any | undefined = this.modalSettlementStatement;
    if (modalTemplate) {
      this.modalSettlementStatement.openModal(modalTemplate['modalSettlementStatement']);
    }
  }

  generateStatement() {
    const modalTemplate: any | undefined = this.modalSettlementBillReport;
    if (modalTemplate) {
    this.modalSettlementBillReport.openModal(modalTemplate['modalSettlementBillReport']);
    }
  }

}
