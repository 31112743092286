import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { SettlementsService } from 'src/app/services/settlements.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { BreadcrumbService } from 'xng-breadcrumb';
import { environment } from 'src/environments/environment';
import { IconsService } from 'src/app/shared/services/icons.service';
import { ImageService } from 'src/app/shared/services/image.service';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-tenant',
  template: `
    <div class="row">
      <div class="col-sm-12 offset-sm-0 col-lg-9 offset-lg-3 mt-4">
        <div class="row mb-4">
        
          <div class="col-md-7">
            <h1 class="mt-4">Unidades</h1>
            <p>Configura las propiedades de la unidad y los inquilinos asociados.</p>
          </div>
          <div class="col-md-5 text-end mt-4">
            <button type="button" (click)="generateBill()" [disabled]="pending_bills" class="btn btn-outline-primary btn-lg rounded-5 shadow border border-3 border-primary" >
              <fa-icon [icon]="icons.faPlus" class="me-2"></fa-icon>
              Pago adelantado
            </button>
            <button class="btn btn-primary btn-lg rounded-5 ms-3" routerLink="/pagos/recibos/new/{{unit.id}}">Crear recibo</button>
          </div>
          <div *ngIf="!loading">
            <xng-breadcrumb separator="/" class="float-start"></xng-breadcrumb>
          </div>
          <div *ngIf="loading">
            <p class="mb-0" style="font-size: 16px !important;">Cargando...</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-5">
            <h5 class="mb-0">Condominio</h5>
            <h2>{{settlement?.name}}</h2>
            <h4>{{building?.name}}</h4>
            <!-- hr -->
            <div class="card rounded-5 shadow border-0 overflow-hidden mt-4">
              <div class="card-header" style="background-color: #A881D0;">
                <div class="row">
                  <div class="col-md-8 text-start">
                    <h5 class="text-light mt-3 mb-0">UNIDAD</h5>
                    <h2 class="text-light mt-0 mb-0">{{unit?.name}}</h2>
                    <h4 class="text-light text-sm mt-0 mb-3">{{unit?.number}}</h4>
                  </div>
                  <div class="col-md-4 text-end pt-3 text-white">
                    <button class="btn btn-outline-light" (click)="editingUnit=!editingUnit">
                    <fa-icon [icon]="icons.faEdit"></fa-icon>
                    </button>
                  </div>
                  <hr>
                  <div class="col-md-12">
                    <div class="row text-light">
                      <div class="col-md-3 text-center">{{unit.area}} M<sup>2</sup></div>
                      <div class="col-md-3 text-center">
                        <fa-icon [icon]="icons.faBath" class="text-light me-2"></fa-icon>
                        {{unit.bathrooms}}
                      </div>
                      <div class="col-md-3 text-center">
                        <fa-icon [icon]="icons.faBed" class="text-light me-2"></fa-icon>
                        {{unit.bedrooms}}
                      </div>
                      <div class="col-md-3 text-center">
                        <fa-icon [icon]="icons.faCar" class="text-light me-2"></fa-icon>
                        {{unit.parking}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body p-4" *ngIf="editingUnit">
                <app-form-unit [unit]="unit" (updateUnit)="updateUnit($event)"></app-form-unit>              
              </div>
            </div>
            <div class="card rounded-5 shadow mt-3 mb-5">
              <div class="card-body p-4">
                <div id="servicios" class="tab-pane servicios" >
                    <div *ngIf="subscriptions.length==0">
                      <h5>Agrega subscripciones para está unidad</h5>
                      <div class="list-group">
                        <div class="list-group-item clearfix" *ngFor="let utility of utilities">
                          <p class="text-start float-start pt-1 mb-0">{{utility.name}}</p>
                          <button class="btn btn-sm btn-outline-primary text-end float-end pb-0" (click)="addSubscription(utility.id)" [disabled]="savingSubscription">
                            <fa-icon [icon]="icons.faPlus"></fa-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                    
                    <div class="mt-3" *ngIf="subscriptions.length>0">
                      <h5>Subscripciones asignadas a la unidad</h5>
                      <div class="list-group mt-3">
                        <li class="list-group-item" *ngFor="let subscription of subscriptions">
                          <span>{{subscription.utility.name}}</span>
                        </li>
                      </div>
                    </div>

                    <div class="list-group mt-4" *ngIf="!unit.tanque_id">
                      <h5>Asigna un tanque al local</h5>
                      <div class="list-group-item" *ngFor="let tanque of building.tanques">
                        <div class="row">
                          <div class="col-md-1 text-center mt-2">
                            <ngx-emoji emoji="alembic" [style]="{ bottom: '10px', right: '2px' }"></ngx-emoji>
                          </div>
                          <div class="col-md-5">
                            <h5 class="mb-0 mt-2">{{tanque.name}}</h5>
                          </div>
                          <div class="col-md-4">
                            <p class="mb-0">
                              <span class="ms-2" style="font-size: 12px;">Capacidad: {{tanque.capacidad}} {{tanque.unidad}}</span>
                            </p>
                          </div>
                          <div class="col-md-2 clearfix text-end">
                            <button type="button" class="btn btn-sm btn-outline-info ms-2" (click)="addTanque(tanque.id)">
                              <fa-icon [icon]="icons.faPlus" size="1x"></fa-icon>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="list-group mt-4" *ngIf="unit.tanque_id">
                      <h5>Tanque asignado al local</h5>
                      <div class="list-group-item" *ngIf="tanque">
                        <div class="row">
                          <div class="col-md-1">
                            <ngx-emoji emoji="alembic" [style]="{ bottom: '10px', right: '2px' }"></ngx-emoji>
                          </div>
                          <div class="col-md-9">
                            <h5 class="mb-0 mt-1">{{tanque.name}}</h5>
                          </div>
                          <div class="col-md-2 text-end">
                            <button type="button" class="btn btn-sm btn-outline-danger" (click)="removeTanque(tanque)">
                              <fa-icon [icon]="icons.faTrashAlt"></fa-icon>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- div class="form-group mb-4 mt-4" *ngIf="!unit.contrato">
                      <label for="name" class="form-label ms-2">Contrato del local</label>
                      <input type="file" class="form-control" (change)="loadPDF($event)">
                    </!-->
                    <!-- div class="form-group mb-4 mt-4" *ngIf="unit.contrato">
                      <label for="name" class="form-label ms-2">Contrato del local</label><br>
                      <button type="button" (click)="download()">
                        <fa-icon [icon]="icons.faFilePdf" class="text-primary me-2"></fa-icon>
                        Descargar contrato
                      </button>
                      <button class="btn btn-link ms-2" (click)="removeContrato()">
                        <fa-icon [icon]="icons.faTrashAlt" class="text-danger ms-2"></fa-icon>
                      </button>
                    </!-->
                    <section class="mt-3">
                      <section app-contract-unit 
                        [settlement]="settlement"
                        [tenants]="tenants"
                        [unit]="unit"
                        [tanque]="tanque"
                      ></section>
                    </section>
                  </div>
              </div>
            </div>
          </div>
        
        <div class="col-12 col-md-7">
          <section 
            app-meter-unit 
            [medidores]="medidores"
            [unit]="unit"
            [permissions]="permissions"
          ></section>
          <div class="card rounded-5 shadow overflow-hidden mb-4">
            <div class="card-body p-4">
              <div class="row">
                <div class="col-12">
                  <h5 class="mb-4">INQUILINOS</h5>
                </div>
                <div class="col-12">
                  <div class="list-group rounded-4" *ngIf="!addingTenant">
                    <div class="list-group-item p-0" *ngFor="let tenant of tenants">
                      <app-item-sm-tenant [tenant]="tenant"></app-item-sm-tenant>
                      <!-- app-item-tenant [tenant]="tenant" class="row bg-light rounded-4 border border-1 m-2"></!-->
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-3 mt-3" *ngIf="unit.tenants">
                <div class="col-md-8 text-start">
                  <button class="btn btn-outline-info rounded-5 me-2" (click)="plusTenant()">+ Agregar inquilino</button>
                  <!-- button class="btn btn-outline-info" (click)="plusExistingTenant()">+ Agregar existente</!-->
                </div>
                <div class="col-md-4 text-end">
                  <p class="mt-2 mb-0 text-muted text-sm">Total inquilinos: {{unit.tenants.length}}</p>
                </div>                    
              </div>
              <div class="row">
                <div *ngIf="addingExistingTenant">
                  <app-search-tenants></app-search-tenants>
                </div>
                <div *ngIf="addingTenant">
                  <app-form-tenant
                    [addingTenant]="addingTenant"
                    (saveTenant)="createTenant($event)"
                    (cancelForm)="cancelTenant()"
                  ></app-form-tenant>
                </div>
              </div>
            </div>
          </div>
          <div class="card rounded-5 shadow border-0">
            <div class="card-header bg-white border-bottom-0 pb-0 pt-4 ps-2 pe-2">
              <div class="d-flex mb-4">
                <div class="flex-fill me-2">
                  <button class="btn btn-outline-secondary" (click)="generateReport()">Edo. cuenta</button>
                </div>
                <div class="text-start">
                  <app-filter-advanced
                    [toDate]="toDate"
                    [fromDate]="fromDate"
                    (dateRange)="updateDate($event)"
                  ></app-filter-advanced>
                </div>
                <div class="ms-2 text-end">
                  <button class="btn btn-primary rounded-5">
                    <fa-icon [icon]="icons.faSearch"></fa-icon>
                  </button>
                </div>
              </div>
              <ul class="nav nav-tabs" #tabs>
                <li class="nav-item">
                  <a #menu1 class="nav-link active" data-toggle="tab" href="#historial" (click)="tab($event)" >Pagos</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" data-toggle="tab" href="#unpaid" (click)="tab($event)">No emitidos</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" data-toggle="tab" href="#adeudos" (click)="tab($event)">No pagados</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#facturas" (click)="tab($event)">Facturas</a>
                </li>
              </ul>
            </div>
            <div class="card-body p-4 pt-3">
              <div #tabContent class="tab-content">
                <div id="historial" class="tab-pane historial active ps-3 pe-3">
                  <table class="table table-sm table-striped mt-3">
                    <thead>
                      <tr>
                        <th># Recibo</th>
                        <th>Tipo</th>
                        <th class="text-center">Fecha pago</th>
                        <th class="text-end">Total</th>
                      </tr>
                    </thead>
                    <tfoot *ngIf="summary.paid?.list.length>0" class="bg-secondary">
                      <tr>
                        <td colspan="4" class="mt-3 border-0"></td>
                      </tr>
                      <tr class="bg-secondary">
                        <td colspan="2" class="border-0"></td>
                        <td class="text-end text-secondary">Sub total</td>
                        <td class="text-end text-secondary">{{summary.paid.sub_total | currency}}</td>
                      </tr>
                      <tr>
                        <td colspan="2" class="border-0"></td>
                        <td class="text-end text-secondary">I.V.A.</td>
                        <td class="text-end text-secondary">{{summary.paid.taxes | currency}}</td>
                      </tr>
                      <tr>
                        <td colspan="2" class="border-0"></td>
                        <td class="text-end">Total</td>
                        <td class="text-end">{{summary.paid.total | currency}}</td>
                      </tr>
                    </tfoot>
                    <tbody *ngIf="summary.paid?.list.length>0">
                      <tr *ngFor="let bill of summary.paid?.list">
                        <td class="">{{bill.id}}</td>
                        <td class="text-start "><span *ngIf="bill.adv">Anticipo</span><span *ngIf="!bill.adv">Normal</span></td>
                        <td class="text-center">{{ bill.paid_date | date: 'dd/MM/yy'}}</td>
                        <td class="text-end ">{{bill.total | currency}}</td>
                        <td class="text-end">
                          <button ngbDropdownItem (click)="downloadPaymentReceipt(bill)" class="btn btn-link text-primary">
                            <fa-icon [icon]="icons.faDownload"></fa-icon>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                    <tbody *ngIf="summary.paid?.list.length==0">
                      <tr>
                        <td colspan="4" class="text-center p-5">No hay registros</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div id="unpaid" class="tab-pane unpaid">
                  <table class="table table-sm table-striped mt-3">
                    <thead>
                      <tr>
                        <th># Recibo</th>
                        <th>Tipo</th>
                        <th class="text-center">Estatus</th>
                        <th class="text-end">Total</th>
                      </tr>
                    </thead>
                    <tfoot *ngIf="summary.unpaid?.list.length>0" class="bg-secondary">
                      <tr>
                        <td colspan="4" class="mt-3 border-0"></td>
                      </tr>
                      <tr class="bg-secondary">
                        <td colspan="2" class="border-0"></td>
                        <td class="text-end text-secondary">Sub total</td>
                        <td class="text-end text-secondary">{{summary.unpaid.sub_total | currency}}</td>
                      </tr>
                      <tr>
                        <td colspan="2" class="border-0"></td>
                        <td class="text-end text-secondary">I.V.A.</td>
                        <td class="text-end text-secondary">{{summary.unpaid.taxes | currency}}</td>
                      </tr>
                      <tr>
                        <td colspan="2" class="border-0"></td>
                        <td class="text-end">Total</td>
                        <td class="text-end">{{summary.unpaid.total | currency}}</td>
                      </tr>
                    </tfoot>
                    <tbody *ngIf="summary.unpaid?.list.length>0">
                      <tr *ngFor="let bill of summary.unpaid.list">
                        <td class=""><a href="/pagos/recibos/{{bill.id}}">{{bill.id}}-{{bill.reference}}</a></td>
                        <td class=""><span *ngIf="bill.adv">Anticipo</span><span *ngIf="!bill.adv">Normal</span></td>
                        <td class="text-center ">{{ overduebill(bill) }}</td>
                        <td class="text-end ">{{bill.total | currency}}</td>
                      </tr>
                    </tbody>
                    <tbody *ngIf="summary.unpaid?.list.length==0">
                      <tr>
                        <td colspan="4" class="text-center p-5">No hay registros</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div id="adeudos" class="tab-pane adeudos">
                  <table class="table table-sm table-striped mt-3">
                    <thead>
                      <tr>
                        <th># Recibo</th>
                        <th>Tipo</th>
                        <th class="text-center">Estatus</th>
                        <th class="text-end">Total</th>
                        <th class="text-end"></th>
                      </tr>
                    </thead>
                    <tfoot *ngIf="summary.pending?.list.length>0" class="bg-secondary">
                      <tr>
                        <td colspan="4" class="mt-3 border-0"></td>
                      </tr>
                      <tr class="bg-secondary">
                        <td colspan="2" class="border-0"></td>
                        <td class="text-end text-secondary">Sub total</td>
                        <td class="text-end text-secondary">{{summary.pending.sub_total | currency}}</td>
                      </tr>
                      <tr>
                        <td colspan="2" class="border-0"></td>
                        <td class="text-end text-secondary">I.V.A.</td>
                        <td class="text-end text-secondary">{{summary.pending.taxes | currency}}</td>
                      </tr>
                      <tr>
                        <td colspan="2" class="border-0"></td>
                        <td class="text-end">Total</td>
                        <td class="text-end">{{summary.pending.total | currency}}</td>
                      </tr>
                    </tfoot>
                    <tbody *ngIf="summary.pending?.list.length>0">
                      <tr *ngFor="let bill of summary.pending.list">
                        <td class=""><a href="/pagos/recibos/{{bill.id}}">#{{bill.id}} {{bill.reference}}</a></td>
                        <td class=""><span *ngIf="bill.adv">Anticipo</span><span *ngIf="!bill.adv">Normal</span></td>
                        <td class="text-center">{{ overduebill(bill) }}</td>
                        <td class="text-end">{{bill.total | currency}}</td>
                        <td class="text-end">
                          <button class="btn btn-link p-0" (click)="downloadReceipt(bill)">
                            <fa-icon [icon]="icons.faDownload" class="text-primary"></fa-icon>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                    <tbody *ngIf="summary.pending?.list.length==0">
                      <tr>
                        <td colspan="4" class="text-center p-5">No hay registros</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div id="facturas" class="tab-pane facturas" >
                  <table class="table table-sm table-striped mt-3">
                    <thead>
                      <tr>
                        <th># Factura</th>
                        <th>Tipo</th>
                        <th class="text-center">Estatus</th>
                        <th class="text-end">Total</th>
                      </tr>
                    </thead>
                    <tfoot *ngIf="summary.invoiced?.list.length>0" class="bg-secondary">
                      <tr>
                        <td colspan="4" class="mt-3 border-0"></td>
                      </tr>
                      <tr class="bg-secondary">
                        <td colspan="2" class="border-0"></td>
                        <td class="text-secondary text-end">Sub total</td>
                        <td class="text-end text-secondary">{{summary.invoiced.sub_total | currency}}</td>
                      </tr>
                      <tr>
                        <td colspan="2" class="border-0"></td>
                        <td class="text-secondary text-end">I.V.A.</td>
                        <td class="text-end text-secondary">{{summary.invoiced.taxes | currency}}</td>
                      </tr>
                      <tr>
                        <td colspan="2" class="border-0"></td>
                        <td class="text-end">Total</td>
                        <td class="text-end">{{summary.invoiced.total | currency}}</td>
                      </tr>
                    </tfoot>
                    <tbody *ngIf="summary.invoiced?.list.length>0">
                      <tr *ngFor="let bill of summary.invoiced.list">
                        <td class=""><a href="/pagos/recibos/{{bill.id}}">{{bill.reference}}</a></td>
                        <td class=""><span *ngIf="bill.adv">Anticipo</span><span *ngIf="!bill.adv">Normal</span></td>
                        <td class="text-center ">{{ overduebill(bill) }}</td>
                        <td class="text-end ">{{bill.total | currency}}</td>
                      </tr>
                    </tbody>
                    <tbody *ngIf="summary.invoiced?.list.length==0">
                      <tr>
                        <td colspan="4" class="text-center p-5">No hay registros</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>  
  </div>
  <app-modal-tenant></app-modal-tenant>  
  `,
  styleUrls: []
})
export class TenantComponent {
  @ViewChild('tabs') tabs!: ElementRef | undefined;
  @ViewChild('tabContent') tabContent!: ElementRef | undefined;
  @ViewChild('menu1') menu1!: ElementRef | undefined;

  apiUrl: string = environment.AWS_REST_WSPREFIX;
  receiptUrl: string = environment.RECIBOS_REST_WSPREFIX;

  activeMaintenance: boolean = false;

  profile: any = null;
  permissions: any = null;

  settlement: any = {};
  building: any = {};
  tanque: any = {};
  unitId: number = 0;
  unit: any = {};
  summary: any = {};

  bills: any[] = [];
  debit: any[] = [];
  tenants: any[] = [];
  subscriptions: any[] = [];
  utilities: any[] = [];
  medidores: any[] = [];
  units: any[] = [];

  loading: boolean = true;
  pending_bills: boolean = false;
  addingTenant: boolean = false;
  addingMedidor: boolean = false;
  addingExistingTenant: boolean = false;
  savingSubscription: boolean = false;
  editingUnit: boolean = false;

  editingMedidor: boolean = false;

  unitForm: FormGroup = new FormGroup({});

  link: string = '';
  avatarURL: string = "";
  last_meter: string = "";

  query: any = {
    unit_id: 0,
    date_from: '',
    date_to: '',
  };

  a = new Date();
  fromDate: any; // = "2023/12/01"; // Date = new Date();
  toDate: any; // = "2024/02/02"; // Date = new Date();

  constructor(
    private store: SettlementsService,
    private route: Router,
    private activateRoute: ActivatedRoute,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private breadcrumbService: BreadcrumbService,
    public icons: IconsService,
    public images: ImageService
  ) {
    this.profile = localStorage.getItem('profile') ? JSON.parse(localStorage.getItem('profile') || '{}').profile : {};
    this.permissions = localStorage.getItem('permissions') ? JSON.parse(localStorage.getItem('permissions') || '{}') : {};
    this.activateRoute.params.subscribe(params => {
      this.unitId = params['id'];

      this.loadUnit(this.unitId);

      this.fromDate = new NgbDate(this.a.getFullYear(), this.a.getMonth(), 1);
      this.toDate = new NgbDate(this.a.getFullYear(), this.a.getMonth()+1, 29);
      this.query.unit_id = this.unitId;
      this.query.date_from = `${this.fromDate.year}-${this.fromDate.month}-${this.fromDate.day}`;
      this.query.date_to = `${this.toDate.year}-${this.toDate.month}-${this.toDate.day}`;
    });
  }

  ngOnInit(): void {}

  updateDate(event: any) {
    this.fromDate = event.from;
    this.toDate = event.to;
    //
    this.query.date_from = `${this.fromDate.year}-${this.fromDate.month}-${this.fromDate.day}`;
    this.query.date_to = `${this.toDate.year}-${this.toDate.month}-${this.toDate.day}`;

    this.loadBills(this.query);
  }

  download() {
    window.open(`${this.link}.pdf`, '_blank');
  }

  protected loadUnit(unitId: any): void {
    this.store.getUnit(this.unitId)
    .subscribe({
      next: async (data: any) => {
        // console.log('data => ', data);
        this.unit = data;
        this.tenants = data.tenants;
        this.link = data.contrato ? `${this.apiUrl}/storage/${data.contrato?.key.substring(0,2)}/${data.contrato?.key.substring(2,4)}/${data.contrato?.key}` : '';
        this.medidores = data.gas_meters;

        // this.unitForm.patchValue(data);

        if (data.tanque_id) {
          this.store.getTanque(data.tanque_id).subscribe((tanque: any) => { this.tanque = tanque; });
        }

        // this.store.getGasMeters({ unit_id: this.unitId }).subscribe((data: any) => {
          // this.medidores = data.data;
        // });

        this.store.getUnitSettlement(unitId).subscribe({
          next: (data: any) => {
            this.settlement = data.settlement;
            this.building = data.building;

            // Load data

            console.log('READY TO LOAD DATA !!!!!!!!!')
            this.loadUtilities(this.unit);
            this.loadBills(this.query);
            

            this.store.getUnitsByBuilding(data.building.id).subscribe((data: any) => {
              this.units = data;
            });

            this.breadcrumbService.set('@Index', `Comercial`);
            this.breadcrumbService.set('@unitName', `${this.unit.name}`);
            this.breadcrumbService.set('@buildingName', `${this.settlement.name}`);

            this.loading = false;
          },
          error: (error: any) => {
            console.log('error => ', error);
            this.toastr.error('Unidad no pudo ser cargada. Intenta de nuevo más tarde', 'Error');
          }
        });        
      },
      error: (error: any) => {
        console.log('error => ', error);
        this.toastr.error('Unidad no pudo ser cargada. Intenta de nuevo más tarde', 'Error');
      }
    });
  }

  tab(e: any) {
    e.preventDefault();
    //
    this.tabs?.nativeElement.querySelectorAll('.nav-link').forEach((tab: any) => {
      tab.classList.remove('active');
    });
    this.tabContent?.nativeElement.querySelectorAll('.tab-pane').forEach((tabPane: any) => {
      tabPane.classList.remove('active');
    });
    e.target.classList.add('active');
    this.tabContent?.nativeElement.querySelector(`.${e.target.href.split('#')[1]}`)?.classList.add('active');
  }

  nativeElement(activeTab: any) {
    throw new Error('Method not implemented.');
  }

  get ufc() {
    return this.unitForm.controls;
  }

  loadPDF(event: any): void {
    const reader = new FileReader();
    const ctl = this;
    var base64;
    var fileToLoad = event.target.files[0];
    reader.onload = function(fileLoadedEvent) {
      base64 = fileLoadedEvent.target!.result;
      // Print data in console
      console.log(base64);
      ctl.unitForm.patchValue({
        contrato: base64
      });
      let payload: any = {
        contrato: base64
      }
      ctl.savePDF(payload);
    };
    // Convert data to base64
    reader.readAsDataURL(fileToLoad);
  }

  savePDF(payload: any) {
    this.store.updateUnitContrato(this.unit.id, payload).subscribe((data: any) => {
      this.loadUnit(this.unit.id);
    });
  }

  removeContrato() {
    this.unitForm.patchValue({
      contrato: null,
    });
  }

  overduebill(bill: any) {
    return new Date(bill.due_date) < new Date() ? "Vencido" : 'Vigente';
  }

  selectUnitType(e: any) {
    // console.log('selectUnitType => ', e.target.value);
    this.unitForm.patchValue({
      unit_type_id: e.target.value
    });
  }

  loadUtilities(unit: any) {
    console.log('loadUtilities => ', unit);
    this.store.getSubscription(this.unitId).subscribe((data: any) => {
      this.subscriptions = data;
      console.log('subscriptions X=> ', this.subscriptions);
    });

    this.store.getUtilities({ page: 1, per_page: 100 }).subscribe((allUtilities: any) => {
      this.utilities = [];
      console.log('allUtilities ===> ', allUtilities);
      console.log('unit ===> ', this.unit.subscriptions);
      if (this.unit.subscriptions && this.unit.subscriptions.length > 0) {
        this.unit.subscriptions.map((subscription: any) => {
          allUtilities.data.map((utility: any) => {

            if (subscription.utility_id != utility.id) {
              this.utilities.push(utility);
            }
            allUtilities.data.filter((utility: any) => utility.id != subscription.utility_id);
          });
        });
      } else {
        this.utilities = allUtilities.data;
      }
    });
  }

  loadBills(query: any) {
    this.store.getBillByUnitSummary(
      query.unit_id, 
      { 
        created_at_since: query.date_from,
        created_at_to: query.date_to
      }).subscribe((data: any) => {
        // console.log('SUMMARY data ======> ', data);
        this.summary = data;
    });
  }

  

  /*buildUnitForm() {
    this.unitForm = this.fb.group({      
      name: ['', Validators.required],
      number: [''],
      inquilinos: [''],
      bedrooms: [''],
      bathrooms: [''],
      parking: [''],
      area: [''],
      unit_type_id: [''],
      floor_id: ['', Validators.required],
      factor: [''],
      admin_price: [''],
      interests: [''],
      reconexion_fee: [''],
      discount: [''],
    });
  }*/

  generateBill() {
    this.route.navigate([`/pagos/recibos/unit/${this.unitId}`]);
  }

  addTanque(id: any) {
    this.store.updateUnit(this.unit.id, { tanque_id: id }).subscribe({
      next: (data: any) =>  {
        this.toastr.success('Unidad se actualizó correctamente.', 'Éxito');
        this.loadUnit(this.unit.id);
      },
      error: (error: any) => {
        console.log('error => ', error);
        this.toastr.error('Unidad no pudo ser actualizada.', 'Error');
      }
    });
  }

  removeTanque(tanque: any) {
    this.store.updateUnit(this.unit.id, { tanque_id: null }).subscribe({
      next: (data: any) =>  {
        this.toastr.success('Unidad se actualizó correctamente.', 'Éxito');
        this.loadUnit(this.unit.id);
        // this.addingUnit = false;
        // this.unitForm.reset();
        // this.setBuilding(this.selectedBuilding.id);
      },
      error: (error: any) => {
        console.log('error => ', error);
        this.toastr.error('Unidad no pudo ser actualizada.', 'Error');
      }
    });
  }

  updateUnit(form: any) {
    console.log(form);
    
    this.store.updateUnit(this.unit.id, form).subscribe({
      next: (data: any) =>  {
        this.toastr.success('Unidad se actualizó correctamente.', 'Éxito');
        this.loadUnit(this.unit.id);
      },
      error: (error: any) => {
        console.log('error => ', error);
        this.toastr.error('Unidad no pudo ser actualizada.', 'Error');
      }
    });
  }

  

  addSubscription(utilityId: any) {
    this.savingSubscription = true;
    const payload: any = {
      unit_id: this.unitId,
      utility_id: utilityId,
    };
    console.log(payload);

    this.store.createSubscription(payload).subscribe({
      next: (data: any) => {
        console.log(data);
        this.savingSubscription = false;
        this.loadUnit(this.unit.id);
      },
      error: (err: any) => {
        this.savingSubscription = false;
      }
    });
  }

  plusTenant() {
    this.addingTenant = true;
  }

  cancelTenant() {
    this.addingTenant = false;
  }

  createTenant(data: any) {
    const payload: any = {
      avatar: data.avatar,
      name: data.name,
      last_name: data.last_name,
      email: data.email,
      phone: data.phone,
      unit_id: this.unit.id,
    }

    this.store.createTenant(payload).subscribe({
      next: (data: any) => {
        this.addingTenant = false;
        this.toastr.success('Inquilino se creó correctamente.', 'Éxito');

        let address: any = {
          tenant_id: data.id,
          country: this.building.settlement.country,
          city: 'CX',
          state: this.building.settlement.state,
          address: this.building.settlement.address,
          zip_code: this.building.settlement.zip,
        };

        this.store.createTenantAddress(address).subscribe((address: any) => {
          console.log('address => ', address);
        });

        this.loadUnit(this.unit.id);

      },
      error: (err: any) => {
        console.log(err);
        this.toastr.error(`Inquilino no pudo ser creado. \n ${err.error.exception}`, 'Error');
      }
    });
  }

  

  downloadReceipt(bill: any) {
    window.open(`${this.receiptUrl}/public/${bill.pdf}`, '_blank');
  }

  async downloadPaymentReceipt(bill: any) {
    console.log('downloadPaymentReceipt => ', bill);
    console.log('profile => ', this.profile);

    await this.store.getTransactionByBill(bill.id).subscribe(async (transaction: any) => {
      const img = this.images.buildURL(this.profile.logo);
      const imx: any = await this.images.getBase64ImageFromUrl(img);
      this.profile.logo = `${imx.split(',')[1]}`;

      let payload = {
        bill: bill,
        transaction: transaction,
        profile: this.profile
      }

      console.log('payload => ', payload);

      this.store.downloadPaymentReceipt(payload).subscribe((data: any) => {
        const int8Array = new Uint8Array(data.result.data);
          const blob = new Blob([int8Array], { type: 'application/pdf' });

          var fileURL = window.URL.createObjectURL(blob);
          var link = document.createElement('a');

          link.href = fileURL;
          link.download = data.filename;
          link.click();
      });
    });
  }

  generateReport() {
    this.store.getUnitStatement(this.unitId).subscribe((data: any) => {
      this.store.reportUnitStatement(data).subscribe((data: any) => {
        const int8Array = new Uint8Array(data.result.data);
        const blob = new Blob([int8Array], { type: 'application/pdf' });

        var fileURL = window.URL.createObjectURL(blob);
        var link = document.createElement('a');

        link.href = fileURL;
        link.download = data.filename;
        link.click();
      });
    });
  }

}
