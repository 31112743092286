import { Component, Input } from '@angular/core';
import { SettlementsService } from 'src/app/services/settlements.service';
import { ngxCsv } from 'ngx-csv';
import * as bs from 'bootstrap';
import * as xml2js from 'xml2js';
import { IconsService } from 'src/app/shared/services/icons.service';

@Component({
  selector: 'app-report-invoices-notas',
  template: `
    <button type="button" class="btn btn-info rounded-5 shadow w-100" (click)="modalReport()">
      <fa-icon [icon]="icons.faFileExcel" class="me-2"></fa-icon>
      Notas crédito
    </button>
    <app-modal-invoices-notas [profile]="profile" [loading]="loading" (createReport)="generateReport($event)"></app-modal-invoices-notas>
  `,
  styles: [
  ]
})
export class ReportInvoicesNotasComponent {
  @Input() profile: any;

  loading: boolean = false;

  payload: any = {
    page: 1,
    per_page: 100000,
    tipo: 2,
    canceled: false,
    created_at_since: '',
    created_at_to: '',
    sort: 'created_at',
    order: 'DESC'
  };

  constructor(
    private store: SettlementsService,
    public icons: IconsService
  ) { }

  modalReport() {
    new bs.Modal(<HTMLInputElement>document.getElementById('modalInvoicesNotas')).show();
  }

  generateReport(data: any) {
    this.loading = true;
    console.log("REPORTE notas ===> ", data);
    this.payload.created_at_since = data.created_at_since;
    this.payload.created_at_to = data.created_at_to;
    this.payload.profile_id = this.profile.id;
    this.store.getInvoicesQuery(this.payload).subscribe(async (res: any) => {
      let report: any[] = [];
      await res.data.map(async (invoice: any) => {
        const parser = new xml2js.Parser({
          trim: true,            
          explicitArray: true,            
        });
        await parser.parseString(invoice.cfdixml, (err: any, result: any) => {
          // console.log('result => ', result);
          // console.log('receptor ==> ', result['cfdi:Comprobante']['cfdi:Receptor'][0]['$']);
          // console.log('WAIT ====> ', result['cfdi:Comprobante']);
          const main = result['cfdi:Comprobante']['$'];
          const receptor = result['cfdi:Comprobante']['cfdi:Receptor'][0]['$'];
          const complemento = result['cfdi:Comprobante']['cfdi:Complemento'][0]['tfd:TimbreFiscalDigital'][0]['$'];

          report.push({
            serie: main['Serie'],
            folio: main['Folio'],
            fecha: main['Fecha'],
            uuid: complemento['UUID'],
            rfc: receptor['Rfc'],
            razon: receptor['Nombre'],            
            subTotal: main['SubTotal'],
            total: main['Total'],
          });
        });
      });

      console.log('REPORT BUILT ===> ', report);
      let options = {
        title: 'Reporte de facturas de notas de crédito',
        headers: ['Serie', 'Folio', 'Fecha', 'Folio fiscal', 'RFC receptor', 'Razón social', 'Subtotal', 'Total'],
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalseparator: '.',
        showLabels: true,
        showTitle: true,
        useBom: true,
      };

      new ngxCsv(report, `reporte_factuas_notas_credito`, options);
      this.loading = false;
    });
  }
}
