import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { SettlementsService } from 'src/app/services/settlements.service';
import { IconsService } from 'src/app/shared/services/icons.service';

@Component({
  selector: 'app-modal-settlement-bill-report',
  template: `
    <ng-template #modalSettlementBillReport let-modal>
      <div class="modal-header">
        <div class="modal-title">Reporte de condominio</div>
      </div>
      <div class="modal-body p-4">
        <form [formGroup]="formBillReport">
          <div class="form-group mt-4">
            <label for="created_at_since" class="form-label text-sm">Fecha inicial</label>
            <input type="date" class="form-control " formControlName="created_at_since" placeholder="Fecha inicial" bsDatepicker>
          </div>
          <div class="form-group mt-4">
            <label for="created_at_to" class="form-label text-sm">Fecha final</label>
            <input type="date" class="form-control" formControlName="created_at_to" placeholder="Fecha final" bsDatepicker>
          </div>
          <div class="form-group text-end mt-5">
            <button type="button" class="btn btn-primary" (click)="generateReport()">Generar reporte</button>
          </div>
        </form>
      </div>
    </ng-template>
  `,
  styleUrls: []
})
export class ModalSettlementBillReportComponent {
  @Input() settlement : any;
  @Output() updateDismiss: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('modalSettlementBillReport') modalSettlementBillReport !: ElementRef;

  private modalRef: NgbModalRef | undefined;
  formBillReport: FormGroup = new FormGroup({});

  constructor(
    private store: SettlementsService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private modalService: NgbModal,
    public icons: IconsService
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.formBillReport = this.fb.group({
      created_at_since: ['', Validators.required],
      created_at_to: ['', Validators.required]
    });
  }
  
  generateReport() {
    this.store.getAccountStatementBySettlement({}).subscribe({
      next: (response: any) => {
        console.log('getAccountStatementBySettlement response => ', response);
      }, error: (error: any) => {
        console.log('error => ', error);
      }
    });
  }

  openModal(modalContent: TemplateRef<any>) {
    this.modalRef = this.modalService.open(modalContent);
  }

  dismiss() {
    if (this.modalRef) {
      this.updateDismiss.emit();
      this.modalRef.dismiss();
    }
  }
}
