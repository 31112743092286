import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SettlementsService } from 'src/app/services/settlements.service';
import { IconsService } from 'src/app/shared/services/icons.service';

@Component({
  selector: 'app-modal-invoices-generic-canceled',
  template: `
    <div #modalInvoicesGeneric id="modalInvoicesGeneric" class="modal fade" tabindex="-1"  aria-labelledby="modalInvoicesGeneric" aria-hidden="true">
      <div class="modal-dialog modal-dialog-top">
        <div class="modal-content rounded-5">
          <div class="modal-header">
            <div class="modal-title">Intervalo de fechas</div>
          </div>
          <div class="modal-body p-4">
            <div class="row">
              <div class="col-md-12">
                <form [formGroup]="inventoryForm" (ngSubmit)="generateReport()">
                  <div class="form-group mt-4">
                    <label for="payment_date">Fecha de inicio</label>
                    <input type="date" class="form-control form-conrol-lg" formControlName="created_at_since">                    
                  </div>
                  <div class="form-group mt-4">
                    <label for="payment_date">Fecha de fin</label>
                    <input type="date" class="form-control form-conrol-lg" formControlName="created_at_to">                    
                  </div>
                  <div class="form-group mt-4 text-end">
                    <button type="submit" class="btn btn-primary btn-lg rounded-5">
                      <fa-icon [icon]="icons.faFileExcel" class="me-2" *ngIf="!loading"></fa-icon>
                      <fa-icon [icon]="icons.faCircleNotch" [spin]="true" class="me-2" *ngIf="loading"></fa-icon>
                      Generar reporte
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class ModalInvoicesGenericCanceledComponent {
  @Input() profile: any;
  @Input() loading: any;
  @Output() createReport: EventEmitter<any> = new EventEmitter();
  @ViewChild('modalInvoicesGeneric', { read: ElementRef, static: false }) modalInvoicesGeneric !: ElementRef;

  inventoryForm: FormGroup = new FormGroup({});

  constructor(
    private store: SettlementsService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    public icons: IconsService
  ) {}

  ngOnInit(): void {
    this.inventoryForm = this.fb.group({
      created_at_since: [''],
      created_at_to: ['']
    });
  }

  generateReport() {
    this.createReport.emit(this.inventoryForm.value);
  }
}
